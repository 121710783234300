<template>
  <div>
    <span
      :class="[
        `text-${statusVariant}`,
        `border-${statusVariant}`,
        statusInfo.title === 'PACKED' && !disabled ? 'yellow-badge' : ''
      ]"
      class="border rounded bg-white status-badge"
    >
      <span class="font-weight-bold"> {{ statusInfo.title }} </span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'StatusBadge',
  props: {
    deliveryStatus: {
      type: Object,
      default: () => ({}),
    },
    packingStatus: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    statusInfo() {
      const status = this.deliveryStatus?.status?.toUpperCase()
      switch (status) {
        case 'DELIVERED':
          return {
            title: 'DELIVERED',
            color: 'success',
          }
        case 'PICKED':
          return {
            title: 'PICKED',
            color: 'warning',
          }
        case 'CREATED':
          if (
            this.packingStatus?.status
            && !['NULL', 'CREATED'].includes(this.packingStatus?.status?.toUpperCase())
          ) {
            return {
              title: 'PACKED',
              color: 'yellow',
            }
          }
          return {
            title: 'CONFIRMED',
            color: 'primary',
          }
        case 'DELETED':
          return {
            title: 'DELETED',
            color: 'danger',
          }
        default:
          return {
            title: 'PENDING',
            color: 'secondary',
          }
      }
    },
    statusVariant() {
      if (this.disabled && this.statusInfo.title !== 'PACKED') {
        return 'lighten-2'
      }
      return this.statusInfo.color
    },
  },
}
</script>

<style lang="scss" scoped>
.status-badge {
  padding: 2px 12px;
  font-size: 12px;
}
.yellow-badge {
    color: #f1e34d !important; /* Yellow text color */
    border-color: #f1e34d !important; /* Yellow border */
}
</style>
