<template>
  <div>
    <span
      :class="[`text-${statusVariant}`, `border-${statusVariant}`,
               data === 'PACKED' && !disabled ? 'yellow-badge' : '']"
      class="border rounded bg-white status-badge"
    >
      <span class="font-weight-bold"> {{ data }} </span>
    </span>
  </div>
</template>

<script>

export default {
  props: {
    data: { type: String, required: true },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    statusVariant() {
      if (this.$props.disabled && this.data !== 'PACKED') {
        return 'lighten-2'
      }
      switch (this.data) {
        case 'DELETED':
          return 'danger'

        case 'CREATED':
          return 'secondary'

        case 'CONFIRMED':
          return 'primary'

        case 'DELIVERED':
          return 'success'

        case 'PICKED':
          return 'warning'

        case 'PACKED':
          return 'yellow'

        default:
          return 'secondary'
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.status-badge {
    padding: 2px 12px;
    font-size: 12px;
}
.yellow-badge {
    color: #f1e34d !important; /* Yellow text color */
    border-color: #f1e34d !important; /* Yellow border */
}
</style>
