<template>
  <div>
    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        label="Small Spinner"
        variant="primary"
      />
    </div>

    <b-alert
      variant="danger"
      :show="loadingError ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ loadingError }}
        </p>
      </div>
    </b-alert>

    <b-table
      v-if="!loading && !loadingError"
      :items="items"
      :fields="tableFields"
      striped
      hover
      show-empty
      class="compact-table1"
      no-local-sorting
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      caption-top
      @sort-changed="sortingChanged"
    >
      <template #cell(bill_no)="{item}">
        <span class="d-flex align-items-center">
          {{ item.bill_no }}
          <span
            v-if="item.is_modified"
            class="is-modified-bill"
          />
          <span
            v-if="item.is_urgent"
            class="is-urgent-bill"
          />
          <feather-icon
            v-if="item.bill_delivery_info && item.bill_delivery_info.delivery_otp && item.bill_delivery_info.delivery_otp.verified"
            v-b-tooltip.hover="{ boundary: 'window' }"
            title="OTP Verified"
            icon="CheckIcon"
            size="16"
            class="text-primary mx-1"
          />

        </span>
      </template>
      <template #cell(date)="data">
        {{ data.item.bill_delivery_info && data.item.bill_delivery_info.generated_at ? formatedDate(data.item.bill_delivery_info.generated_at ,'DD-MM-YYYY LT') :'-' }}
      </template>
      <template #cell(pay_status)="data">
        <status-icon
          v-if="data.item.pay_status"
          :value="data.item.pay_status"
        />
      </template>

      <template #cell(bill_attachments)="data">
        <span>
          <span
            v-for="(item, index) in data.item.bill_attachments"
            :key="index"
            class="position-relative"
          >
            <a
              :href="item.file"
              target="_blank"
            >  <feather-icon
                 v-b-tooltip.hover="{boundary:'window'}"
                 title="View File"
                 icon="FileIcon"
                 size="20"
                 class="cursor-pointer file-icon"
               />
              <span class="file-number">{{ index + 1 }} </span>
              <span v-if="index != data.item.bill_attachments.length - 1">  &nbsp;</span>
            </a>
          </span>
        </span>
      </template>
      <template #cell(amount)="data">
        {{ formateNumber(data.item.amount) }}
      </template>
      <template #cell(items_count)="data">
        {{ formateNumber(data.item.items_count) }}
      </template>
      <template
        v-if="displayTableCaption"
        #table-caption
      >
        Bills
      </template>
      <template #cell(status)="data">
        <Status
          :delivery-status="data.item.bill_delivery_info"
          :packing-status="data.item.rack_group_status"
        />
      </template>
      <template #cell(user)="data">
        <span>{{ lastUser(data) || '-' }}</span>
      </template>
      <template #cell(action)="data">
        <feather-icon
          v-b-tooltip.hover="{boundary:'window'}"
          title="View Details"
          icon="EyeIcon"
          size="16"
          class="cursor-pointer mr-1"
          @click="handleViewClick(data.item.id, data.item)"
        />
      </template>
    </b-table>
    <detailed-pagination
      v-if="!loading && !loadingError"
      :total-items="totalItems"
      :per-page="perPage"
      :current-page="currentPage"
      @page-changed="(page) => {currentPage = page}"
    />
    <view-bill-details-modal
      v-if="selectedViewId"
      v-model="showModal"
      :bill-data="billData"
      :bill-id="selectedViewId"
      @modal-closed="handleCloseModal"
      @onStatusChange="fetchItems"
    />
  </div>
</template>

<script>
import {
  BSpinner, BTable, BAlert, VBTooltip,
} from 'bootstrap-vue'
import axios from 'axios'
import dateMixin from '@/mixins/dateMixin'
import formatMixin from '@/mixins/formatMixin'
import StatusIcon from '../UI/StatusIcon.vue'
import DetailedPagination from '../UI/DetailedPagination.vue'
import Status from './Status.vue'
import ViewBillDetailsModal from '../TodayBill/ViewBillDetailsModal.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BSpinner, BTable, BAlert, StatusIcon, DetailedPagination, Status, ViewBillDetailsModal,
  },
  mixins: [dateMixin, formatMixin],
  props: {
    filters: {
      type: Object,
      required: true,
    },
    displayTableCaption: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      loadingError: null,
      showModal: false,
      selectedViewId: null,
      billData: null,
      items: [],
      tableFields: [
        { key: 'bill_no', label: 'Bill No.', sortable: true },
        ...(this.$props.filters.isTodayList) ? [{ key: 'party__identifier', label: 'Buyer Name', sortable: true }] : [],
        { key: 'date', label: 'Date', sortable: true },
        { key: 'items_count', label: 'Total Items', class: 'text-right' },
        {
          key: 'amount', label: 'Amount', sortable: true, class: 'text-right',
        },
        { key: 'mode', label: 'Mode', sortable: true },
        { key: 'pay_status', label: 'Pay Status', sortable: true },
        {
          key: 'bill_attachments',
          label: 'Attachments',
          tdStyle: {
            width: '150px',
            'max-width': '150px',
            'overflow-x': 'auto',
            'white-space': 'nowrap',
            display: 'block', // This is important
            position: 'relative', // This helps with width enforcement
          },
          thStyle: {
            width: '150px',
            'max-width': '150px',
          },
        },
        { key: 'status', label: 'Status' },
        { key: 'user', label: 'User' },
        { key: 'action' },
      ],
      openStatementId: null,
      currentPage: 1,
      perPage: 50,
      totalItems: 0,
      sortBy: 'bill_no',
      sortDesc: true,
    }
  },
  watch: {
    filters: {
      handler(newVal, oldVal) {
        if (this.currentPage !== 1) {
          this.currentPage = 1
        } else if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          this.fetchItems()
        }
      },
      deep: true,
    },
    currentPage() {
      this.fetchItems()
    },
  },
  created() {
    this.fetchItems()
  },
  methods: {
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy
      this.sortDesc = ctx.sortDesc
      this.fetchItems()
    },
    fetchItems() {
      this.loading = true

      const params = {
        ...this.filters,
        page: this.currentPage,
        per_page: this.perPage,
        sort_by: this.sortBy,
        sort_desc: this.sortDesc,
      }
      const queryString = Object.keys(params)
        .map(key => {
          if (params[key] !== null && params[key] !== undefined) {
            if (Array.isArray(params[key])) {
              return params[key].map(value => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join('&')
            }
            return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
          }
          return ''
        })
        .filter(Boolean)
        .join('&')

      axios.get(`/accounts/bills?${queryString}`)
        .then(res => {
          this.items = res.data.results
          this.totalItems = res.data.count
          this.loadingError = null
          this.loading = false
        })
        .catch(error => {
          this.loadingError = error?.response?.data?.error || 'Something went wrong'
          this.loading = false
        })
    },
    handleCloseModal() {
      this.selectedViewId = null
    },
    async handleViewClick(id, data) {
      this.selectedViewId = id
      this.showModal = true
      this.billData = data
    },
    lastUser(data) {
      if (data.item?.bill_delivery_info?.delivered_by_fullname) {
        return data.item.bill_delivery_info.delivered_by_fullname
      } if (data.item?.bill_delivery_info?.picked_by_fullname) {
        return data.item.bill_delivery_info.picked_by_fullname
      } if (data.item?.rack_group_status?.packing_initiated_by) {
        return data.item?.rack_group_status?.packing_initiated_by
      } if (data.item?.bill_delivery_info?.generated_by) {
        return data.item?.bill_delivery_info?.generated_by
      }
      return '' // Assuming user data is in item
    },
  },
}
</script>
<style scoped>
.file-icon{
  transform: scale(1.4, 1.2)
}
.file-number{
  position: absolute;
  top: 2px;
  left: 2px;
  color: black;
  /* border: 2px solid firebrick; */
}
</style>
